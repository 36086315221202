import React from "react";

import "react-toastify/dist/ReactToastify.css";
import { Card, Col, Row } from "react-bootstrap";

export default function Home() {
  return (
    <Card className="shadow mr-5  ml-5 mt-5 p-3">
      <Row>
        <Col>&nbsp;</Col>
        <Col>&nbsp;</Col>
      </Row>
    </Card>
  );
}
