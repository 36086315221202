import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useFormik } from "formik";
import { helpHttp } from "../../helpers/helpAxios";
import { ACTUALIZAR_CLAVE } from "../../helpers/endpoints";
import { useCallback, useEffect, useState, useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { setMessageAction } from "../../actions/messageActions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* import PasswordStrengthBar from "react-password-strength-bar"; */
import routers from "../../helpers/routers";
import * as Yup from "yup";

const initialForm = {
  codigo: "",
  clave: "",
  reClave: "",
  token: "",
  claveActual: "",
};

export default function BlanqueoClave() {
  initialForm.codigo = useParams().codigo;
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dynamicAction = "blanqueClave";
  const rPassword = useRef(null);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Ejecutar recaptcha aún no disponible");
      return;
    }

    const result = await executeRecaptcha(dynamicAction);
    return result;
    // Do whatever you want with the token
  }, [executeRecaptcha, dynamicAction]);

  // si quiero una validacion mejor mirar
  // https://javascript.tutorialink.com/formik-yup-password-strength-validation-with-react/

  const validationSchema = Yup.object({
    clave: Yup.string()
      .trim()
      .matches(
        /^(?=.*[a-zA-Z]{3})(?=.*\d{3})(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{1}).{7,15}$/,
        "La clave debe tener 3 caracteres alfabéticos, 3 numéricos, 1 carácter especial y una longitud máxima de 15"
      )
      .required("La nueva clave es obligatoria."),
    reClave: Yup.string()
      .required("La confirmación de la clave es obligatoria.")
      .oneOf(
        [Yup.ref("clave"), null],
        "El clave y la confirmación no son iguales"
      ),
  });

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit: (values) => {
      onSubmitCallback(values);
      // setShow(false);
    },
  });

  const mensajeSistema = (message, tipo) => {
    dispatch(
      setMessageAction({
        type: tipo,
        mensaje: message
          ? message
          : "Es este momento no podemos procesar la solicitud. Intente mas tarde",
      })
    );
  };

  const onSubmitCallback = async (values) => {
    values.token = await handleReCaptchaVerify();

    await helpHttp()
      .put(ACTUALIZAR_CLAVE, { data: values })
      .then(() => {
        mensajeSistema("Se realizo en envío con éxito", "success");
        history.push("/");
      })
      .catch((error) => {
        formik.resetForm();
        mensajeSistema(error.message, "ERROR");
      });
  };
  useEffect(() => {
    if (loggedIn) {
      history.push(routers.blanqueoClaveSinParametro);
    }
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }
  }, [executeRecaptcha, dynamicAction]);
  const scorePassword = (pass) => {
    let score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    let letters = new Object();
    for (var i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (var check in variations) {
      variationCount += variations[check] == true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
    /* 
    PasswordStrengthBar; */
  };
  return (
    <Container className="mt-5">
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Row>
          <Col sm="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Card className="mt-2 text-center" border="primary">
              <Card.Header as="h5">Cambiar clave</Card.Header>
              <Card.Body>
                {!loggedIn ? (
                  <Form.Control
                    type="hidden"
                    name="codigo"
                    id="codigo"
                    value={formik.values.codigo}
                  />
                ) : (
                  <Form.Group control="claveActual">
                    <Form.Label htmlFor="claveActual">Clave actual</Form.Label>
                    <Form.Control
                      type="password"
                      name="claveActual"
                      id="claveActual"
                      placeholder="Ingrese la clave actual"
                      value={formik.values.claveActual}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.claveActual && formik.errors.claveActual
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {formik.touched.claveActual && (
                        <p> {formik.errors.claveActual}</p>
                      )}{" "}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                <Form.Group control="clave">
                  <Form.Label htmlFor="clave">Nueva clave</Form.Label>
                  <Form.Control
                    type="password"
                    name="clave"
                    id="clave"
                    value={formik.values.clave}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.clave && formik.errors.clave}
                    aria-describedby="passwordHelpBlock"
                    placeholder="Ingrese la clave"
                  />
                  <Form.Text id="passwordHelpBlock" muted>
                    La clave debe tener 3 caracteres alfabéticos, 3 numéricos, 1
                    carácter especial y una longitud máxima de 15
                  </Form.Text>

                  {/*  Toggle button to switch between password and text */}
                  {/*  <Button onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? "Hide" : "Show"} Password
                  </Button> */}
                  {/*    <PasswordStrengthBar
                    password={formik.values.clave}
                    minLength={7}
                    onChangeScore={(score, feedback) => {
                      console.log(score, feedback);
                    }}
                    score={scorePassword(password)}
                    scoreWords={[
                      "Muy corta",
                      "Débil",
                      "Aceptable",
                      "Buena",
                      "Fuerte",
                    ]}
                    style={{ width: 200 }}
                    shortScoreWord="Muy corta"
                  />
                  <div className="pwstrength_viewport_progress"></div> */}
                  <Form.Control.Feedback type="invalid">
                    {formik.touched.clave && <p> {formik.errors.clave}</p>}{" "}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group control="reClave">
                  <Form.Label htmlFor="clave">Confirma la clave</Form.Label>
                  <Form.Control
                    type="password"
                    name="reClave"
                    id="reClave"
                    placeholder="Vuelva a introducir la clave"
                    value={formik.values.reClave}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.reClave && formik.errors.reClave}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.touched.reClave && <p> {formik.errors.reClave}</p>}{" "}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="primary btn-block"
                  type="submit"
                  disabled={!formik.dirty}
                >
                  Cambiar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
